import React from "react"
import Image6 from "../imageComponents/Image6"
import { Link } from "gatsby"

const StockistsCard = ({ data }) => {
  return (
    <div className="col-span-full rounded-2xl overflow-hidden bg-white shadow-xl">
      <Link to="/stockists">
        <div className="lg:flex">
          <div className="lg:w-3/5">
            <Image6 />
          </div>

          <div className="p-5 mx-auto my-auto">
            <h2 className="font-extrabold tracking-tight text-gray-900 text-4xl">
              Find us near you
            </h2>
            <br />
            <h2 className="font-bold tracking-tight text-gray-900 text-2xl">
              Distribution points
            </h2>
            <br />
            <h2 className="text-lg text-indigo-600 font-semibold ">
              Retailers in WA, OR & CA
            </h2>
            <br />
            <Link
              to="/stockists"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              See our stockists
            </Link>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default StockistsCard
