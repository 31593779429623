import React from "react"
import Image5 from "../imageComponents/Image5"

const BuyOnline = ({ data }) => {
  return (
    <div className="col-span-full rounded-2xl overflow-hidden bg-white shadow-xl">
      <a href="https://club.archibaldjames.com/shop">
        <div className="lg:flex">
          <div className="lg:w-3/5">
            <Image5 />
          </div>

          <div className="p-5 mx-auto my-auto">
            <h2 className="font-extrabold tracking-tight text-gray-900 text-4xl">
              Shop online
            </h2>
            <br />
            <h2 className="font-bold tracking-tight text-gray-900 text-2xl">
              Order direct
            </h2>
            <br />
            <h2 className="text-lg text-indigo-600 font-semibold ">
              Select items available à la carte
            </h2>
            <br />
            <a
              href="https://club.archibaldjames.com/shop"
              className="inline-flex items-center justify-center px-5
            py-3 border border-transparent text-base font-medium rounded-md
            text-white bg-indigo-600 hover:bg-indigo-700"
            >
              {" "}
              Shop now
            </a>
          </div>
        </div>
      </a>
    </div>
  )
}

export default BuyOnline
