import React from "react"

import SEO from "../components/Seo"
import GridLayout from "../components/layouts/GridLayout"
import Layout from "../components/layouts/Layout"

import TastingRoomCard from "../components/homepageFeatureCards/TastingRoomCard"
import BuyOnline from "../components/homepageFeatureCards/BuyOnline"
import JoinClub from "../components/homepageFeatureCards/JoinClub"
import StockistsCard from "../components/homepageFeatureCards/StockistsCard"

const Taste = () => {
  return (
    <div>
      <SEO title="Taste" description="Taste Archibald James" />
      <Layout>
        <GridLayout>
          <TastingRoomCard />
          <JoinClub />
          <BuyOnline />
          <StockistsCard />
        </GridLayout>
      </Layout>
    </div>
  )
}

export default Taste
